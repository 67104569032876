import 'select2/dist/js/select2'
import 'select2/dist/js/i18n/es'
import 'select2/dist/js/i18n/en'

export default class Select2 {

  static selector = '[data-behavior~="select2"]'

  static start() {
    const elements = document.querySelectorAll(this.selector)

    for (let element of elements) {
      new Select2(element)
    }
  }

  constructor(element) {
    this.element = element

    this.bindEvents()
    this.build()
  }

  bindEvents() {
    this.build  = this.build.bind(this)
  }

  build() {
    this.element.select2()
  }
}
