import 'icheck'

export default class Icheck {

  static selector = 'input'

  static start() {
    const elements = document.querySelectorAll(this.selector)

    for (let element of elements) {
      new Icheck(element)
    }
  }

  constructor(element) {
    this.element = $(element)

    this.bindEvents()
    this.build()
  }

  bindEvents() {
    this.build  = this.build.bind(this)
  }

  build() {
    this.element.iCheck({
      checkboxClass: 'icheckbox_square-green',
      radioClass:    'iradio_square-green'
    })
  }
}
